.gradient {
  width: 85%;
  white-space: nowrap;
  position: relative;
  display: inline-block;
  top: 4px;
  padding-bottom: 15px;
}


.gradient .domainMin {
  position: absolute;
  left: 0;
  bottom: 3px;
}
.gradient .domainMed {
  position: absolute;
  right: 25%;
  left: 25%;
  text-align: center;
  bottom: 3px;
}
.gradient .domainMax {
  position: absolute;
  right: 0;
  bottom: 3px;
}

.gradStep {
  display: inline-block;
  height: 20px;
  width: 1%;
}
