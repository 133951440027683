.feeCard {
  min-width: 0;
  //margin: 0 auto 0 auto;
}

.feeTag {
  display: block;
  height: 50px;
  margin: 0 auto 0 auto;
  font-size: 2vw;
  padding-top: 11px;
  font-weight: bold;
  text-align: center;
}
